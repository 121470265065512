:root {
    /* Cores */
    --dommus-color: #183b64;
    --dommus-color-2: #f7941d;
    --dommus-color-secondary: #64b5f6;
    --dommus-color-cinza: #868aa8;
    --dommus-color-green: #4caf50;
    --dommus-color-red: #f44336;
    --dommus-color-dark: #353252;
    --dommus-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width: 6px;
    --dommus-overflow-height: 6px;

    /*  */
    --smooth: 200ms ease-in-out;
}

* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

::-webkit-input-placeholder {
    color: #a0a0a0 !important;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #a0a0a0 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #a0a0a0 !important;
}

:-ms-input-placeholder {
    color: #a0a0a0 !important;
}

*::-webkit-scrollbar-track {
    background-color: #ddd;
}
*::-webkit-scrollbar {
    width: var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 12px;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--dommus-color);
    border-radius: 10px;
}
