footer{
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: 70px;
    background: linear-gradient(90deg, rgba(24, 59, 100, 1) 0%, rgba(24, 59, 100, 0.85) 50%, rgba(24, 59, 100, 1) 100%);
    text-align: center;
}

footer a{
    color: var(--dommus-color-2);
    font-weight: 800;
    text-decoration: none;
}

footer a:hover{
    color: white;
}

@media (max-width: 425px) {
    footer{
        flex-direction: column;
        gap: 2px;
    }
    
}