.header-section{
    width: 100%;
    min-height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    /* filter: sepia(100%) hue-rotate(190deg) saturate(500%); */
    
}

.header-section .empreendimento-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.header-section .empreendimento-section .logo-empreendimento{
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 3px solid white;
}

.header-section .empreendimento-section .nome-empreendimento{
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 800;
    color: white;
    text-shadow: 1px 1px 1px black;
}

.empreendimento-sem-foto-section{
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(24, 59, 100, 1) 0%, rgba(24, 59, 100, 0.85) 50%, rgba(24, 59, 100, 1) 100%);
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.empreendimento-sem-foto-section .empreendimento-sem-foto{
    border-radius: 50%;
    border: 3px solid white;
    padding: 1rem 2rem;
    font-size: 2.2rem;
    color: white;
}

.empreendimento-sem-foto-section .nome-empreendimento{
    color: white;
    font-weight: 900;
    font-size: 2rem;
}

@media (max-width: 425px) {
    .header-section .empreendimento-section {
        flex-direction: column;
    }
    .header-section .empreendimento-section .logo-empreendimento{
        width: 7rem;
        height: 7rem;
    }
}