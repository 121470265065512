.form-questionario{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.atualizacao-section{
    color: #888;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 10px;
    font-weight: 600;
}

.atualizacao-section-mobile{
    display: flex;
    color: #888;
    flex-direction: column;
    gap: 5px;
    font-size: 0.9rem;
    font-weight: 600;
}
.btn-enviar-questionario{
    justify-content: center;
}

.btn-enviar-questionario .btn{
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: fit-content;
}
