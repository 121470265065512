.link-expirado-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link-expirado-section img{
    width: 8rem;   
    height: 8rem;
}
.link-expirado-section .text-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-expirado-section .text-section span{
    font-size: 2rem;
    font-weight: 700;
}