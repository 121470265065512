.pergunta-opv-item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.pergunta-opv-item .enunciado-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    font-size: 0.9rem;
    font-weight: 700;
    color: #444;
}

.pergunta-opv-item .enunciado-section .number{
    font-weight: 800;
    color: #999;
    font-size: 1rem;
}

.resposta-checkbox-section{
    display: flex;
    flex-direction: row;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}

.resposta-checkbox-section div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-right: 1px solid #ddd;
    padding-right: 10px;
    /* align-items: flex-start; */
}
.resposta-checkbox-section div input{
    cursor: pointer;
}

.resposta-checkbox-section:last-child div:last-child{
    border: none;
    padding-right: 0;
}

.enunciado-obrigatorio{
    margin-left: 5px;
    color: red;
}

