.mensagem-respondido-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 50px 20px;
}

.mensagem-respondido-section img{
    width: 10rem;
}
.mensagem-respondido-section span{
    font-size: 1.7rem;
    font-weight: 700;
    color: #0BA06E;
}
