.mensagem-ja-respondida-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px 20px;
}

.mensagem-ja-respondida-section img {
    width: 8rem;
}

.mensagem-ja-respondida-section .mensagem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.mensagem-ja-respondida-section .mensagem span {
    font-weight: 600;
    color: #444;
    text-align: center;
}

.mensagem-ja-respondida-section .mensagem span:first-child{
    font-size: 1.5rem;
}

.mensagem-ja-respondida-section .mensagem span:last-child{
    font-size: 1.1rem;
}
